import i18n from '@/i18n';

export const methods = {
  name: 'methods',
  list: [
    {
      value: 'gel',
      label: i18n.t('categories.methods.gel'),
    },
    {
      value: 'acrylic',
      label: i18n.t('categories.methods.acrylic'),
    },
    {
      value: 'acylogel',
      label: i18n.t('categories.methods.acrylogel'),
    },
    {
      value: 'manicure',
      label: i18n.t('categories.methods.manicure'),
    },
    {
      value: 'beauty',
      label: 'Beauty',
    },
    {
      value: 'french_manicure',
      label: i18n.t('categories.methods.french_manicure'),
    },
    {
      value: 'combined_manicure',
      label: i18n.t('categories.methods.combined_manicure'),
    },
    {
      value: 'nipper_manicure',
      label: i18n.t('categories.methods.nipper_manicure'),
    },
    {
      value: 'nipper_free_manicure',
      label: i18n.t('categories.methods.nipper_free_manicure'),
    },
    {
      value: 'electricfile',
      label: i18n.t('categories.methods.electricfile'),
    },
    {
      value: 'pedicure',
      label: i18n.t('categories.methods.pedicure'),
    },
    {
      value: 'other',
      label: i18n.t('categories.methods.other'),
    },
  ],
};

export const shapes = {
  name: 'shapes',
  list: [
    {
      value: 'square',
      label: i18n.t('categories.shapes.square'),
    },
    {
      value: 'almond',
      label: i18n.t('categories.shapes.almond'),
    },
    {
      value: 'oval',
      label: i18n.t('categories.shapes.oval'),
    },
    {
      value: 'butterfly',
      label: i18n.t('categories.shapes.butterfly'),
    },
    {
      value: 'stitetto',
      label: i18n.t('categories.shapes.stitetto'),
    },
    {
      value: 'coffin',
      label: i18n.t('categories.shapes.coffin'),
    },
    {
      value: 'pipe',
      label: i18n.t('categories.shapes.pipe'),
    },
    {
      value: 'russian_almond',
      label: i18n.t('categories.shapes.russian_almond'),
    },
    {
      value: 'gothic_almond',
      label: i18n.t('categories.shapes.gothic_almond'),
    },
    {
      value: 'gothic_pipe',
      label: i18n.t('categories.shapes.gothic_pipe'),
    },
    {
      value: 'edge',
      label: i18n.t('categories.shapes.edge'),
    },
    {
      value: 'bridge',
      label: i18n.t('categories.shapes.bridge'),
    },
    {
      value: 'other',
      label: i18n.t('categories.shapes.other'),
    },
  ],
};

export const art = {
  name: 'art',
  list: [
    {
      value: 'ombre',
      label: i18n.t('categories.art.ombre'),
    },
    {
      value: 'stones',
      label: i18n.t('categories.art.stones'),
    },
    {
      value: 'marble',
      label: i18n.t('categories.art.marble'),
    },
    {
      value: 'ornaments',
      label: i18n.t('categories.art.ornaments'),
    },
    {
      value: 'silk',
      label: i18n.t('categories.art.silk'),
    },
    {
      value: 'zhostovo',
      label: i18n.t('categories.art.zhostovo'),
    },
    {
      value: 'one_stroke',
      label: i18n.t('categories.art.one_stroke'),
    },
    {
      value: 'thin_lines',
      label: i18n.t('categories.art.thin_lines'),
    },
    {
      value: 'glitter',
      label: i18n.t('categories.art.glitter'),
    },
    {
      value: 'jewellery',
      label: i18n.t('categories.art.jewellery'),
    },
    {
      value: 'wedding',
      label: i18n.t('categories.art.wedding'),
    },
    {
      value: 'iconic_arts',
      label: i18n.t('categories.art.iconic_arts'),
    },
    {
      value: 'flowers',
      label: i18n.t('categories.art.flowers'),
    },
    {
      value: 'butterfly',
      label: i18n.t('categories.art.butterfly'),
    },
    {
      value: 'stylograph',
      label: i18n.t('categories.art.stylograph'),
    },
    {
      value: 'airbrush',
      label: i18n.t('categories.art.airbrush'),
    },
    {
      value: 'juicy_fruits',
      label: i18n.t('categories.art.juicy_fruits'),
    },
    {
      value: 'animals',
      label: i18n.t('categories.art.animals'),
    },
    {
      value: 'roses',
      label: i18n.t('categories.art.roses'),
    },
    {
      value: 'animal_patterns',
      label: i18n.t('categories.art.animal_patterns'),
    },
    {
      value: '3d',
      label: i18n.t('categories.art.3d'),
    },
    {
      value: '2d',
      label: i18n.t('categories.art.2d'),
    },
    {
      value: 'decorated_ring',
      label: i18n.t('categories.art.decorated_ring'),
    },
    {
      value: 'watercolour',
      label: i18n.t('categories.art.watercolour'),
    },
    {
      value: 'jungle',
      label: i18n.t('categories.art.jungle'),
    },
    {
      value: 'mix_media',
      label: i18n.t('categories.art.mix_media'),
    },
    {
      value: 'face_art',
      label: i18n.t('categories.art.face_art'),
    },
    {
      value: 'other',
      label: i18n.t('categories.art.other'),
    },
  ],
};
