import { art, shapes, methods } from '@/data/categories';
import i18n from '@/i18n';
import store from '@/store';

export default (course) => {
  const [mainLanguage, ...subtitlesLanguages] = course.languages ? course.languages.split(',') : [''];
  return {
    ...course,
    price: course.price / 100 || null,
    willLearn: course.willLearn ? course.willLearn.split(',').filter(Boolean) : [],
    needs: course.needs ? course.needs.split(',').filter(Boolean) : [],
    difficulty: course.difficulty ? {
      value: course.difficulty,
      label: i18n.t(`levels.${course.difficulty}`),
    } : null,
    manicureMethods: methods.list.filter((method) => course.manicureMethods?.split(',').includes(method.value)),
    manicureNailArts: art.list.filter((artStyle) => course.manicureNailArts?.split(',').includes(artStyle.value)),
    manicureShapes: shapes.list.filter((shape) => course.manicureShapes?.split(',').includes(shape.value)),
    language: store.state.language.languagesList.map((language) => ({ value: language.key, label: language.label }))
      .find((language) => language.value === mainLanguage),
    subtitlesLanguages,
    lessons: course.lessons
      ? course.lessons.map((lesson) => ({ ...lesson, subtitles: JSON.parse(lesson.subtitles) }))
      : [],
    certificateNameBoxPositionX: course.certificateNameBoxPositionX || -100,
    certificateNameBoxPositionY: course.certificateNameBoxPositionY || 0,
    certificateDateBoxPositionX: course.certificateDateBoxPositionX || 100,
    certificateDateBoxPositionY: course.certificateDateBoxPositionY || 0,
    certificateNameBoxScale: course.certificateNameBoxScale || 30,
    certificateDateBoxScale: course.certificateDateBoxScale || 30,
  };
};
