<template>
  <div class="add-course">
    <div class="container" v-if="course">
      <base-heading bold="semiBold" class="dashboard-heading">
        {{ pageHeading }}
      </base-heading>

      <transition name="page-fade" mode="out-in">
        <div class="add-course__grid">
          <base-tabs
            type="filled"
            :tabs="tabs"
            v-model="activeTab"
            @input="handleChangeTab"
            class="add-course__tabs"
          />

          <base-button
            @click="publishCourse"
            thin
            :is-loading="isLoading"
            class="add-course__publish"
          >
            {{ $t('addCourse.save') }}
          </base-button>

          <main class="add-course__content">
            <transition name="page-fade" mode="out-in">
              <component
                ref="tab"
                :is="`add-step-${activeTab}`"
                :course="course"
                :activeLesson="activeLesson"
                :disabled="!isMutable()"
                @update-data="updateData"
                @mount="$emit('info-mount')"
              />
            </transition>
          </main>

          <lesson-playlist
            class="add-course__playlist"
            :class="isNavigationVisible() && 'add-course__playlist--with-nav'"
            :course="course"
            :activeLesson="activeLesson"
            :disabled="!isMutable()"
            @update-data="updateData({ lessons: $event })"
            @change-active="changeActiveLesson"
            @add-lesson="addNewLesson"
            @delete-lesson="deleteLesson"
          />
        </div>
      </transition>
    </div>
    <add-course-modal v-if="newCourseModalStatus" @update-data="submitModal"/>
  </div>
</template>

<script>
import course from '@/api/services/course';
import { uuid } from '@/helpers';
import convertCourse from '@/helpers/convertCourse';

export default {
  name: 'AddCoursePage',
  data: () => ({
    activeTab: 'info', // [info, videos, certificate]
    course: null,
    activeLesson: null,
    newCourseModalStatus: false,
    errorMessage: '',
    isLoading: false,
  }),
  inject: ['isNavigationVisible'],
  props: {
    slug: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    tabs() {
      return [
        {
          key: 'info',
          label: `1. ${this.$t('addCourse.steps')[0]}`,
        },
        {
          key: 'videos',
          label: `2. ${this.$t('addCourse.steps')[1]}`,
        },
        {
          key: 'certificate',
          label: `3. ${this.$t('addCourse.steps')[2]}`,
        },
      ];
    },
    pageHeading() {
      return this.$t('editCourse.heading');
    },
  },
  methods: {
    updateData(data) {
      this.course = { ...this.course, ...data };
    },
    submitModal(data) {
      this.course = convertCourse(data);
      this.newCourseModalStatus = false;
    },
    addNewLesson() {
      const newLesson = {
        assetId: '',
        assetStatus: '',
        id: uuid(),
        isNew: true,
        name: '',
        subtitles: [],
        videoDuration: 0,
      };
      this.updateData({ lessons: [...this.course.lessons, newLesson] });
      this.changeActiveLesson(newLesson);
    },
    changeActiveLesson(lesson) {
      this.activeLesson = lesson;
      this.activeTab = 'videos';
      const target = document.querySelector('.add-course');

      if (target) {
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    },
    handleChangeTab(tab) {
      if (tab !== 'videos') {
        return;
      }
      if (this.course.lessons.length === 0) {
        this.addNewLesson();
      } else {
        [this.activeLesson] = this.course.lessons;
      }
    },
    async deleteLesson(deletedLesson) {
      this.updateData({
        lessons: this.course.lessons.filter(
          (lesson) => lesson.id !== deletedLesson.id,
        ),
      });
      this.handleChangeTab('videos');

      if (deletedLesson.isNew) {
        return;
      }
      try {
        await course.deleteLesson(this.course.id, deletedLesson.id);
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      }
    },
    isMutable() {
      return (
        this.course.status === 'not_published' || this.course.soldCount === 0
      );
    },
    isInfoMounted() {
      return new Promise((resolve) => {
        if (this.$refs.tab.$el.classList.contains('add-step-info')) resolve();
        this.$on('info-mount', resolve);
      });
    },
    async validateCourse() {
      if (this.activeTab === 'info') {
        await this.isInfoMounted();
        const { tab } = this.$refs;
        const isValid = await tab.validateForm();
        if (!isValid) {
          if (tab.$el) {
            tab.$el.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        }
        return isValid;
      }
      const {
        difficulty,
        price,
        description,
        name,
        imageId,
      } = this.course;
      if (!difficulty || !price || !description || !name || !imageId) {
        this.activeTab = 'info';
        await this.validateCourse();
        return false;
      }
      return true;
    },
    async publishCourse() {
      const isValid = await this.validateCourse();
      if (!isValid) return;

      const {
        difficulty,
        id,
        language,
        lessons,
        manicureMethods,
        manicureNailArts,
        manicureShapes,
        needs,
        previewAssetId,
        previewStartTime,
        price,
        subtitlesLanguages,
        willLearn,
      } = this.course;
      const extractValues = (element) => element.value;
      const PART_TO_PREVIEW = 0.75;
      const courseToRequest = {
        ...this.course,
        difficulty: difficulty.value,
        languages: [language.value, ...subtitlesLanguages].join(','),
        lessons: lessons.map((lesson, index) => ({
          ...lesson,
          subtitles: JSON.stringify(lesson.subtitles),
          sequenceNumber: index,
        })),
        manicureMethods: manicureMethods.map(extractValues)
          .join(','),
        manicureNailArts: manicureNailArts.map(extractValues)
          .join(','),
        manicureShapes: manicureShapes.map(extractValues)
          .join(','),
        needs: needs.join(','),
        previewAssetId:
          this.course.status === 'not_published'
            ? (lessons[lessons.length - 1] != null ? lessons[lessons.length - 1].assetId : previewAssetId)
            : previewAssetId,
        previewStartTime:
          this.course.status === 'not_published'
            ? (lessons[lessons.length - 1] != null ? Math.round(
              lessons[lessons.length - 1].videoDuration * PART_TO_PREVIEW,
            ) : previewStartTime)
            : previewStartTime,
        price: price * 100,
        willLearn: willLearn.join(','),
      };

      try {
        this.isLoading = true;

        await Promise.all(
          courseToRequest.lessons.map(async (lesson) => {
            try {
              if (lesson.isNew) {
                await course.addLesson({
                  ...lesson,
                  id: '',
                }, id);
              } else {
                await course.editLesson(lesson, id, lesson.id);
              }
            } catch (err) {
              throw new Error(err);
            }
          }),
        );

        await course.updateCourse(courseToRequest, this.course.id);

        this.$router.push('/added-courses');

        this.$store.dispatch('notification/spawn', {
          type: 'success',
          title: this.$t('editCourse.success.title'),
          text: this.$t('editCourse.success.desc'),
        });
      } catch (err) {
        console.error(err);
        const { message } = err.response.data;
        if (
          message === 'not_processed_yet'
          || message === 'course_assets_not_assigned'
        ) {
          this.$store.dispatch('notification/spawn', {
            type: 'warning',
            title: this.$t('processing.title'),
            text: this.$t('processing.text'),
          });
          this.$router.push('/added-courses');
        } else {
          this.$store.dispatch('notification/spawn', {
            type: 'error',
            errorMessage: message,
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    activeTab(tab) {
      if (tab !== 'videos') {
        this.activeLesson = null;
      }
    },
  },
  async created() {
    if (!this.slug) {
      this.newCourseModalStatus = true;
      return;
    }
    this.$store.dispatch('ui/setPreloader', true);

    try {
      const { data } = await course.getCourse(this.slug);
      this.course = convertCourse(data);
    } catch (err) {
      this.$store.dispatch('notification/spawn', {
        errorMessage: err.response.data.message,
        type: 'error',
      });
    } finally {
      this.$store.dispatch('ui/setPreloader', false);
    }
  },
};
</script>

<style lang="scss" src="./AddCourse.scss"/>
